import { createRouter, createWebHistory } from 'vue-router';
import HelloWorld from '@/components/HelloWorld.vue'

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    {
      path: '/shared/',
      name: 'HelloWorld',
      component: HelloWorld,
      props: (route) => {
        console.log('Decoded script path:', route.query.script);
        return {
          script: route.query.script
        }
      }
    }
  ]
});

export default router;

