<template>
  <div class="full-height-center">
    <div class="padding">
      <div class="container rounded-24">
        <div v-if="data">
          <div class="image-container">
            <img :src="data.image_url" alt="Photo" class="full-width rounded-16">
            <button @click="togglePlay" v-if="!isPlaying" class="play-button">
              <font-awesome-icon icon="fa-solid fa-play" />
            </button>
          </div>
          <p class="text">{{ data.line }}</p>
          <audio ref="audioPlayer" :src="data.audio_url" @ended="playNextScene"></audio>
        </div>
        <div v-else>
          Loading...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HelloWorld',
  props: {
    script: String
  },
  data() {
    return {
      currentIndex: 0,
      scenes: null,
      data: null,
      isPlaying: false
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        console.log('Script object methods:', this.script);
        const response = await axios.post('https://curiosity-391313.oa.r.appspot.com/curio_feed', {"script": this.script});
        // const response = await axios.post('http://127.0.0.1:8000/curio_feed', {"script": this.scriptObject});
        this.scenes = response.data.curios.flatMap(item => item.curio);
        this.data = this.scenes[this.currentIndex];
      } catch (error) {
        console.error('There was an error!', error);
      }
    },
    togglePlay() {
      this.isPlaying = !this.isPlaying;
      if (this.isPlaying) {
        this.playAudio();
      } else {
        this.$refs.audioPlayer.pause();
      }
    },
    playAudio() {
      this.$refs.audioPlayer.play();
    },
    playNextScene() {
      this.currentIndex++;
      if (this.currentIndex < this.scenes.length) {
        this.data = this.scenes[this.currentIndex];
        this.$nextTick(() => {
          this.$refs.audioPlayer.pause();
          this.$refs.audioPlayer.load(); // Ensure the new source is loaded
          this.playAudio();
        });
      } else {
        this.isPlaying = false;
      }
    }
  }
}
</script>

<style scoped>
html, body {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-height-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.padding {
  padding: 16px;
}

.container {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.05);

  transform: translate(0%, 0%);
  
}

.full-width {
  width: 100%;
  height: auto;
}

.rounded-24 {
  border-radius: 24px;
}

.rounded-16 {
  border-radius: 16px;
}

.text {
  margin-top: 8px;
  height: 180px;
  word-wrap: break-word;
  text-align: left;
}

.play-button {
    position: absolute;
    top: 30%; /* Center the button vertically */
    left: 50%; /* Center the button horizontally */
    transform: translate(-50%, -50%); /* Offset the button by half its width and height */
    border: none;
    background-color: rgba(0, 0, 0, 0.66); /* Semi-transparent background */
    color: white;
    font-size: 32px;
    padding: 16px 36px;
    border-radius: 20px;
    cursor: pointer;
}
</style>
